@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
}

html, body, #root {
  height: 100%;
  width: 100%;
}